import clsx from 'clsx'

// size: large, medium, sm
const TypographyCaps = ({
	children = <></>,
	size = 'medium',
	additionalClasses = '',
	...props
}: {
	children: React.ReactNode
	size?: 'small' | 'medium' | 'large'
	additionalClasses?: string
}) => {
	return (
		<span
			className={clsx(
				size === 'large' && 'font-abc text-capsLarge uppercase',
				size === 'medium' && 'font-abc text-capsMedium uppercase',
				size === 'small' && 'font-abc text-capsSmall uppercase',
				`${additionalClasses}`,
			)}
			{...props}
		>
			{children}
		</span>
	)
}

export default TypographyCaps
