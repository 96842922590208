import clsx from 'clsx'

// size: large, medium, sm, xs
// weight: bold, undefined
const TypographyHeader = ({
	children = <></>,
	size = 'medium',
	isBold = undefined,
	additionalClasses = '',
	...props
}: {
	children: React.ReactNode
	size?: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge'
	isBold?: true | false | undefined
	additionalClasses?: string
}) => {
	return (
		<h3
			className={clsx(
				size === 'xLarge' && isBold && 'font-abc text-headerXLargeBold',
				size === 'xLarge' && !isBold && 'font-abc text-headerXLarge',
				size === 'large' && isBold && 'font-abc text-headerLargeBold',
				size === 'large' && !isBold && 'font-abc text-headerLarge',
				size === 'medium' && isBold && 'font-abc text-headerMediumBold',
				size === 'medium' && !isBold && 'font-abc text-headerMedium',
				size === 'small' && isBold && 'font-abc text-headerSmallBold',
				size === 'small' && !isBold && 'font-abc text-headerSmall',
				size === 'xSmall' && isBold && 'font-abc text-headerXSmallBold',
				size === 'xSmall' && !isBold && 'font-abc text-headerXSmall',
				`${additionalClasses}`,
			)}
			{...props}
		>
			{children}
		</h3>
	)
}

export default TypographyHeader
